import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import NotificationArea from 'component/NotificationArea';
import BlankLogin from 'screen/BlankLogin';
import AppHeader from './AppHeader';
import {withRouter} from 'react-router-dom';
import Router from './Router';
import LoginRouter from './LoginRouter';
import StartupError from './StartupError';
import RuntimeError from './RuntimeError';
import {AppContainer as Container, Body} from 're-cy-cle';
import * as Sentry from "@sentry/react";
import {theme} from 'styles';
import {Helmet} from 'react-helmet';
import {TAB_TITLE_PREFIX} from 'helpers';
import styled from 'styled-components';

export const loginAuthentication = /^\/login\//;

// Top menu is always on top since its part of clientportal, not hoover.
// So second menu shouldnt be scrollable too, for consistency.
const AppContainer = styled(Container)`
    > header {
        top: 0;
        position: sticky;
        z-index: 1002;
    }
`;

@withRouter
@observer
export default class App extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        location: PropTypes.object,
    };

    static childContextTypes = {
        viewStore: PropTypes.object,
    };

    getChildContext() {
        return {
            viewStore: this.props.store,
        };
    }

    componentDidCatch(err) {
        this.hasCrashed = true;
        if (process.env.CY_FRONTEND_SENTRY_DSN) {
            Sentry.captureException(err);
            Sentry.showReportDialog();
        }
    }

    @observable hasCrashed = false;

    render() {
        const {store, location} = this.props;

        if (this.hasCrashed) {
            return <RuntimeError/>;
        }

        let content = null;

        // Firstly check if its login route. When we switch user in INSZO we dont want to be logged in as the old one.
        if (loginAuthentication.test(location.pathname)) {
            content = <LoginRouter store={store} />;
        } else if (store.isAuthenticated) {
            content = <Router store={store}/>;
        } else if (store.bootstrapCode === 200) {
            content = <BlankLogin viewStore={store} />;
        } else if (store.bootstrapCode !== null) {
            // The not null check is important, since we don't want to flash a startup error while the XHR request is running.
            return <StartupError code={store.bootstrapCode} />;
        }


        return (
            <React.Fragment>
                <Helmet>
                    <title>{TAB_TITLE_PREFIX}</title>
                </Helmet>
                <AppContainer data-theme={JSON.stringify(theme)}>
                    <AppHeader store={store} location={location} />
                    <Body>{content}</Body>
                    {store.currentModal ? (
                        <store.currentModal.render
                            viewStore={store}
                            {...store.currentModal}
                        />
                    ) : null}
                    <NotificationArea store={store} />
                </AppContainer>
            </React.Fragment>
        );
    }
}
