import PropTypes from 'prop-types';
import React, { Component } from 'react';
import View from 'spider/store/View';
import { Redirect, Route as BaseRoute, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Load from './Loadable';

// Use the sentry route to enable parameter discovery by react router before
// sending that information to sentry
const Route = Sentry.withSentryRouting(BaseRoute);

const UserOverview = Load(() => import('../screen/User/Overview'));
const UserEdit = Load(() => import('../screen/User/Edit'));
const GlobalValueOverview = Load(() => import('../screen/GlobalValue/Overview'));
const GlobalValueEdit = Load(() => import('../screen/GlobalValue/Edit'));
const GlobalFileOverview = Load(() => import('../screen/GlobalFile/Overview'));
const GlobalFileEdit = Load(() => import('../screen/GlobalFile/Edit'));
const AccessLogEntryOverview = Load(() => import('../screen/AccessLogEntry/Overview'));
const EmailLogOverview = Load(() => import('../screen/User/EmailLog'));

const AccountDetails = Load(() => import('../screen/AccountDetails'));
const Changelog = Load(() => import('../screen/Changelog'));

const HooverboardScreen = Load(() => import('../screen/Hooverboard'));

const AboutScreen = Load(() => import('../screen/AboutScreen'));

const NotFound = Load(() => import('../container/NotFound'));

export default class Router extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(View).isRequired,
    };

    // react-router is a bit too verbose so I made a shorthand
    route = Screen => {
        return rProps => <Screen {...rProps} viewStore={this.props.store}/>;
    };

    redirectFromHome = () => {
        const {currentUser} = this.props.store;
        let uri = (currentUser.isSuperuser || currentUser.isAdmin)? (
            '/assets/user/overview'
        ) : (
            '/hooverboard/'
        );

        return <Redirect to={uri}/>;
    };

    render() {
        return (
            <Switch>
                <Route path="/" exact render={this.redirectFromHome}/>

                <Route path="/assets/user/overview" render={this.route(UserOverview)}/>
                <Route path="/assets/user/:id/edit" render={this.route(UserEdit)}/>
                <Route path="/assets/user/add" render={this.route(UserEdit)}/>
                <Route path="/assets/user/email-log/overview" render={this.route(EmailLogOverview)}/>

                <Route path="/assets/global-value/overview" render={this.route(GlobalValueOverview)}/>
                <Route path="/assets/global-value/:id/edit" render={this.route(GlobalValueEdit)}/>

                <Route path="/assets/global-file/overview" render={this.route(GlobalFileOverview)}/>
                <Route path="/assets/global-file/:id/edit" render={this.route(GlobalFileEdit)}/>

                <Route path="/audit/access-log/overview" render={this.route(AccessLogEntryOverview)}/>

                <Route path="/account/details" render={this.route(AccountDetails)}/>
                <Route path="/account/changelog" render={this.route(Changelog)}/>

                <Route path="/hooverboard/" render={this.route(HooverboardScreen)} />

                <Route path="/about/" render={this.route(AboutScreen)} />

                <Route render={this.route(NotFound)}/>
            </Switch>
        );
    }
}
